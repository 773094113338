function Footer() {

    return (
        <footer>
            <div className="copyright text-center">
                <p>© 2022 - Design and Development @Playman</p>
            </div>
        </footer>

)
}

export default Footer